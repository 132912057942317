import styled from "styled-components";

export const TabList = styled.div`
  overflow-x: auto;
  ul {
    display: flex;
    border: 0.1rem solid #dfdfdf;
    border-radius: 1.5rem;
    overflow: hidden;
    li {
      flex: 1;
      &:not(:last-child) {
        border-right: 0.1rem solid #dfdfdf;
      }
      button {
        display: block;
        width: 100%;
        height: 6rem;
        font-size: 1.8rem;
        color: #111;
        text-align: center;
        letter-spacing: 0.0892857143em;
        background-color: #fff;
        transition: all ease 0.3s;
        &.active {
          background-color: #EC7E2F;
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 1240px) {
    ul {
      width: 120rem;
    }
  }
  @media screen and (max-width: 768px) {
    ul {
      width: 76.8rem;
      border-radius: 1.3rem;
      li {
        button {
          height: 5rem;
          font-size: 1.6rem;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    ul {
      li {
        button {
          height: 4rem;
          font-size: 1.4rem;
        }
      }
    }
  }
`

export const LineTabWrap = styled.div`
  ul {
    display: flex;
    li {
      flex: 1;
      button {
        width: 100%;
        height: 4.8rem;
        font-size: 1.4rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.54);
        border-bottom: 0.1rem solid #dfdfdf;
        &.active {
          color: rgba(0, 0, 0, 0.87);
          font-weight: 500;
          border-bottom: 0.2rem solid #EC7E2F;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    ul {
      li {
        button {
          height: 4rem; 
        }
      }
    }
  }
`
