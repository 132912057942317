import React from 'react'
import { Link } from 'react-router-dom'
import { FooterWrap } from './styles'

const Footer = () => {

  const infoList = [
    {
      title: '이용약관',
      link: '/terms'
    },
    {
      title: '개인정보 취급방침',
      link: '/policy'
    },
    {
      title: '대량거래/체인점 문의',
      link: '/contact'
    },
    {
      title: '고객센터',
      link: '/inquiry?page=0'
    }
  ]

  return (
    <FooterWrap>
      <div className='footer-t'>
        <div className='container'>
          <ul>
            {
              infoList.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.link}>
                      {item.title}
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
      <div className='footer-b'>
        <div className='container'>
          <div className='footer-logo'>
            <Link to={'/'} className='ir'>{process.env.REACT_APP_COMPANY}</Link>
          </div>
          <div className='info-wrap'>
            <h4>{process.env.REACT_APP_COMPANY}</h4>
            <div className='info'>
              <dl>
                <dt>대표 :</dt>
                <dd>{process.env.REACT_APP_COMPANY_MASTER}</dd>
              </dl>
              <dl>
                <dt>사업자등록증 :</dt>
                <dd>{process.env.REACT_APP_COMPANY_REGISTER_NUMBER}</dd>
              </dl>
              {/* <dl>
                <dt>통신판매업 :</dt>
                <dd></dd>
              </dl> */}
            </div>
            <div className='info'>
              <dl>
                <dt>주소 :</dt>
                <dd>{process.env.REACT_APP_ADDRESS}</dd>
              </dl>
            </div>
            <div className='info'>
              <dl>
                <dt>대표전화번호 :</dt>
                <dd>{process.env.REACT_APP_PHONE}</dd>
              </dl>
            </div>
            <p className='copyright'>COPYRIGHT ⓒ {process.env.REACT_APP_COPYRIGHT}. ALL RIGHTS RESERVED.</p>
          </div>
        </div>
      </div>
    </FooterWrap>
  )
}

export default Footer
