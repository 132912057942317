import styled from "styled-components";

export const FindUserWrap = styled.div`
  display: flex;
  min-height: calc(100vh - 44.75rem);
  align-items: center;
  background: url('/image/find-user-bg.png') no-repeat center / cover;
  .box {
    max-width: 48rem;
    width: 100%;
    height: auto;
    box-shadow: 0 0.3rem 0.1rem -0.2rem rgba(0,0,0,0.2), 0 0.2rem 0.2rem 0 rgba(0,0,0,0.14), 0 0.1rem 0.5rem 0 rgba(0,0,0,0.12);
    border-radius: 0.8rem;
    margin: 0 auto;
    background-color: #fff;
    .title-wrap {
      padding: 2.4rem 0;
      border-bottom: 0.1rem solid #dfdfdf;
      h2 {
        font-size: 2.2rem;
        font-weight: 500;
        text-align: center;
      }
    }
    .id-wrap {
      display: block;
      margin-bottom: 1.6rem;
      position: relative;
      margin-top: 1.5rem;
      &::after, &::before {
        content: ' ';
        display: block;
        width: 0;
        height: 2px;
        background-color: #EC7E2F;
        position: absolute;
        bottom: 0;
        transition: all ease 0.3s;
        z-index: 9;
      }
      &::after {
        left: 50%;
      }
      &::before {
        right: 50%;
      }
      span {
        font-size: 1.6rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all ease 0.3s;
        z-index: 9;
      }
      input {
        width: 100%;
        height: 3.2rem;
        border-bottom: 1px solid #dfdfdf;
        outline: none;
        position: relative;
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.87);
      }
      &.active {
        &::after, &::before {
          width: 50%;
        }
        span {
          top: -1.5rem;
          left: -0.5rem;
          transform: scale(0.7);
          color: #EC7E2F;
        }
      }
      &.on {
        span {
          top: -1.5rem;
          left: -0.5rem;
          transform: scale(0.7);
        }
      }
    }
    .btn-wrap {
      padding: 3.2rem;
      border-bottom: 0.1rem solid #dfdfdf;
    }
  }
  @media screen and (max-width: 1240px) {
    min-height: calc(100vh - 36.65rem);
  }
  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 45.65rem);
  }
  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 41.45rem);
    padding: 8rem 1.5rem;
  }
  @media screen and (max-width: 500px) {
    min-height: calc(100vh - 33.85rem);
    padding: 6rem 1.5rem;
    .box {
      .title-wrap {
        padding: 1.5rem 0;
        h2 {
          font-size: 2rem;
        }
      }
      .btn-wrap {
        padding: 1.5rem;
      }
      .id-wrap {
        margin-bottom: 1.5rem;
        span {
          font-size: 1.4rem;
        }
        input {
          height: 3rem;
          font-size: 1.4rem;
        }
      }
    }
  }
`
