import styled from "styled-components";

export const AttachmentWrap = styled.label`
  display: flex;
  align-items: center;
  flex: 1;
  height: 5.6rem;
  border: 0.1rem solid #dfdfdf;
  border-radius: 0.4rem;
  position: relative;
  transition: all ease 0.3s;
  padding: 0 1.5rem;
  &.focus {
    border: 0.1rem solid #EC7E2F;
  }
  .title {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1.4rem;
    position: absolute;
    left: 1rem;
    top: -0.7rem;
    background-color: #fff;
    transition: all ease 0.3s;
    padding: 0 0.5rem;
    &.focus {
      color: #EC7E2F;
    }
  }
  .file-name {
    display: block;
    width: 100%;
    height: 3.2rem;
    outline: none;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  input {
    display: none;
  }
  .material-icons {
    transition: all ease 0.3s;
    opacity: 0;
    color: rgba(0, 0, 0, 0.54);
    font-size: 2.4rem;
    &.hover {
      opacity: 1;
    }
  }
  @media screen and (max-width: 500px) {
    height: 5rem;
    .file-name {
      font-size: 1.4rem;
      height: 3rem;
      line-height: 3rem;
    }
    button {
      font-size: 2rem;
    }
  }
`
