import styled from "styled-components";

export const ContactWrap = styled.div`
  padding: 9rem 0 15rem;
  .img-wrap {
    text-align: center;
  }
  p {
    font-size: 3.2rem;
    font-weight: 400;
    text-align: center;
    margin: 8rem 0;
    word-break: keep-all;
    span {
      font-weight: 600;
      color: #fca932;
    }
    strong {
      font-weight: 600;
    }
  }
  .info-text {
    display: block;
    font-size: 1.6rem;
    color: #333;
    text-align: center;
    margin-top: 3rem;
    word-break: keep-all;
  }
  .info-wrap {
    margin-top: 7rem;
    padding-top: 7rem;
    border-top: 0.1rem solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 20rem;
    }
    .info {
      padding-left: 8rem;
      h4 {
        display: inline-block;
        font-size: 1.6rem;
        line-height: 1;
        font-weight: 600;
        padding: 0.3rem 0.8rem;
        color: #555;
        background-color: #f5f5f5;
        margin-bottom: 1rem;
      }
      dl {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        &.mb0 {
          margin-bottom: 0;
        }
        dt {
          width: 7.5rem;
          font-size: 1.6rem;
          font-weight: 600;
          color: #333;
        }
        dd {
          font-size: 16px;
          color: #333;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 8rem 0;
    p {
      font-size: 2.6rem;
      margin: 6rem 0;
    }
    .info-wrap {
      display: block;
      padding-top: 6rem;
      margin-top: 6rem;
      img {
        display: block;
        margin: 0 auto 3rem;
      }
      .info {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h4 {
          margin-bottom: 2rem;
        }
        dl {
          margin-bottom: 2rem;
          dt {
            width: auto;
            margin-right: 2rem;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 6rem 0;
    p {
      font-size: 1.8rem;
      margin: 4rem 0;
    }
    .info-text {
      font-size: 1.4rem;
      margin-top: 2rem;
    }
    .info-wrap {
      padding-top: 4rem;
      margin-top: 4rem;
      img {
        width: 15rem;
        margin-bottom: 1rem;
      }
      .info {
        h4 {
          font-size: 1.4rem;
          padding: 0.5rem 1rem;
          margin-bottom: 1rem;
        }
        dl {
          margin-bottom: 1rem;
          dt {
            font-size: 1.4rem;
            margin-right: 1rem;
          }
          dd {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
`

export const TitleWrap = styled.div`
  margin-bottom: 4rem;
  h2 {
    font-size: 2.6rem;
    font-weight: 600;
    color: #EC7E2F;
    text-align: center;
    position: relative;
    padding-bottom: 2rem;
    &::after {
      content: '';
      display: block;
      width: 0.8rem;
      height: 0.1rem;
      background-color: #EC7E2F;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 3rem;
    h2 {
      font-size: 2.2rem;
      padding-bottom: 1.5rem;
    }
  }
`
