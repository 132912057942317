import styled from "styled-components";

export const PaginationWrap = styled.ul` 
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  li {
    button {
      height: 3.4rem;
      width: 3.4rem;
      background-color: #fff;
      font-size: 1.4rem;
      color: rgba(0,0,0,.87);
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
      border-radius: 0.4rem;
      transition: all ease 0.1s;
      &.active {
        background-color: #EC7E2F;
        color: #fff;
      }
      img {
        width: 2.4rem;
        filter: invert(0.3);
      }
    }
  }
  @media screen and (max-width: 500px) {
    li {
      button {
        width: 3rem;
        height: 3rem;
        border-radius: 0.2rem;
        img {
          width: 2rem;
        }
      }
    }
  }
`
