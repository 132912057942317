import styled from "styled-components";

export const CountWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  button {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 0.4rem;
    background-color: #EC7E2F;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    font-size: 2.4rem;
    color: #fff;
  }
  input {
    width: 10rem;
    height: 3.6rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.87);
    border-radius: 0.4rem;
    padding: 0 1.2rem;
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    transition: all ease 0.3s;
    &:focus {
      outline: none;
      border: 0.2rem solid #EC7E2F;
      caret-color: #EC7E2F;
    }
  }
  @media screen and (max-width: 768px) {
    input {
      width: 8rem;
    }
  }
  @media screen and (max-width: 500px) {
    button {
      width: 3rem;
      height: 3rem;
      border-radius: 0.2rem;
      font-size: 2rem;
    }
    input {
      width: 6rem;
      height: 3rem;
      border-radius: 0.2rem;
      padding: 0 1rem;
      font-size: 1.4rem;
    }
  }
`
