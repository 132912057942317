import React, { useEffect, useRef, useState } from 'react'
import { Step01Wrap } from './styles'
import CheckLabel from '../../components/checkLabel'
import Button from '../../module/button'
import { JoinStepProps } from './types'
import { PolicyAction } from '../../store/action'

const Step01 = ({handleStep, active}: JoinStepProps) => {

  // Ref
  const step01WrapRef = useRef<HTMLDivElement | null>(null)

  // 전체 동의
  const [allAgree, setAllAgree] = useState(false)
  const handleChangeAllAgree = () => {
    setAllAgree(!allAgree)
    if (!allAgree) {
      setAgree01(true)
      setAgree02(true)
    } else {
      setAgree01(false)
      setAgree02(false)
    }
  }

  // 개인정보 처리방침 동의
  const [agree01, setAgree01] = useState(false)
  const handleChangeAgree01 = () => {
    setAgree01(!agree01)
  }

  // 이용약관 동의
  const [agree02, setAgree02] = useState(false)
  const handleChangeAgree02 = () => {
    setAgree02(!agree02)
  }

  // 전체동의 체크 상태 변경
  useEffect(() => {
    if (agree01 && agree02) {
      setAllAgree(true)
    } else {
      setAllAgree(false)
    }
  }, [agree01, agree02])

  // 동의함 버튼 이벤트
  const handleSubmit = () => {
    if (!agree01) {
      alert('개인정보 처리방침에 동의해주세요.')
    } else if (!agree02) {
      alert('서비스 이용약관에 동의해주세요.')
    } else {
      handleStep()
    }
  }

  // 높이값 변경
  useEffect(() => {
    if (step01WrapRef.current) {
      active ? step01WrapRef.current.style.height = `${step01WrapRef.current.offsetHeight}px` : step01WrapRef.current.style.height = '0px'
    }
  }, [active])


  // Policy Action
  const { getPolicyList } = PolicyAction()

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10
  })

  const [policy, setPolicy] = useState('')
  const [terms, setTerms] = useState('')

  useEffect(() => {
    getPolicyList(pagination)
    .then((res) => {
      const policyData = res?.payload.data?.filter((item: any) => item.code === 'privacy' && item.required === 1)[0]
      const termsData = res?.payload.data?.filter((item: any) => item.code === 'tos' && item.required === 1)[0]
      setPolicy(policyData?.content)
      setTerms(termsData?.content)
    })
  }, [])

  return (
    <Step01Wrap 
      ref={step01WrapRef}
      className={active ? 'active' : ''}
    >
      <div className="all-agree">
        <CheckLabel 
          text='전체 이용약관에 동의합니다.'
          labelId='all-agree'
          checked={allAgree}
          handleChange={handleChangeAllAgree}
        />
      </div>
      <div className="policy-wrap">
        <div className="title-wrap">
          <h4>개인정보 처리방침 &#40;필수&#41;</h4>
          <CheckLabel 
            text='동의'
            labelId='agree01'
            checked={agree01}
            handleChange={handleChangeAgree01}
          />
        </div>
        <div className="text-wrap">
          <div className="contents" dangerouslySetInnerHTML={{ __html: policy }} />
        </div>
      </div>
      <div className="policy-wrap">
        <div className="title-wrap">
          <h4>서비스 이용약관 &#40;필수&#41;</h4>
          <CheckLabel 
            text='동의'
            labelId='agree02'
            checked={agree02}
            handleChange={handleChangeAgree02}
          />
        </div>
        <div className="text-wrap">
          <div className="contents" dangerouslySetInnerHTML={{ __html: terms }} />
        </div>
      </div>
      <div className="btn-wrap">
        <Button 
          text='동의함'
          backgroundColor='#fff'
          borderColor='#EC7E2F'
          textColor='#EC7E2F'
          hoverColor='#EC7E2F'
          handleClick={handleSubmit}
        />
      </div>
    </Step01Wrap>
  )
}

export default Step01
