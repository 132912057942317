import styled from "styled-components";

export const PasswordCheckWrap = styled.div`
  border-top: 0.2rem solid #dfdfdf;
  padding-top: 1rem;
  .box {
    border: 0.1rem solid #dfdfdf;
    height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h4 {
      font-size: 2rem;
      line-height: 1.5;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
    }
    .input-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
      input {
        border: 0.1rem solid #dfdfdf;
        width: 17rem;
        height: 4rem;
        border-radius: 0.4rem;
        padding: 0 1.5rem;
        font-size: 1.6rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.87);
        outline: none;
        transition: all ease 0.3s;
        &:focus {
          border: 0.2rem solid #EC7E2F;
          caret-color: #EC7E2F;
        }
      }
      button {
        width: 6rem;
        height: 4rem;
        border-radius: 0.4rem;
        background-color: #EC7E2F;
        color: #fff;
        font-size: 1.4rem;
        font-weight: 400;
        margin-left: 1rem;
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
      }
    }
  }
  @media screen and (max-width: 500px) {
    .box {
      height: 25rem;
      h4 {
        font-size: 1.8rem;
      }
      .input-wrap {
        margin-top: 2rem;
        input {
          height: 3rem;
          font-size: 1.4rem;
          border-radius: 0.2rem;
        }
        button {
          height: 3rem;
          border-radius: 0.2rem;
        }
      }
    }
  }
`
